.top-banner {
    display: flex;
    flex-direction: column;
    height: 600px;
    background-position: center;
    background-size: cover;
    justify-content: space-between;
    position: relative;
    top: 0;
    left: 0;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .top-banner {
        height: 500px; 
     
    }
}

.vector-1 {
position: absolute;
opacity: 0.9;
top: 0;
}
@media (max-width: 1024px) {
    .vector-1 {
        width: 40%;    
    }
}


.vector-2 {
    position: absolute;
    top: 380px;
    left: -180px;
}

@media (max-width: 768px) {
    .vector-2 {
        width: 40%;  
    }
}

@media (max-width: 1024px) {
    .vector-2 {
        width: 40%; 
        top: 300px;;   
    }
}

.vector-3 {  
    position: absolute;
    right: -10px;
    top: 220px;
    opacity: 0.9;
}

@media (max-width: 1024px) {
    .vector-3 {
        width: 40%;    
        right: -30px;
    }
}
.vector-4 {  
    position: absolute;
    right: 0;
    top: 450px;
    opacity: 0.9;
}
@media (max-width: 1024px) {
    .vector-4 {
        width: 40%;  
    }
}
@media (max-width: 768px) {
    .vector-4 {
        width: 40%;  
        top: 360px;
    }
}
.about-container {
    margin: 5rem 5rem ;
    position: relative;
}

@media (max-width: 1024px) {
    .about-container {
        margin: 3rem 3rem;
    }
}
@media (max-width: 1024px) {
    .about-container {
        margin: 5rem 2rem  0 2rem;
    }
}


.about-desc {
    display: flex; 
    justify-content: space-between;
    margin-top: 100px;
}

@media (max-width: 1024px) {
    .about-desc {
        gap: 1rem;
     
    }
}

.about-box {
    width:50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.about-header {
    color: var(--gray-950, #020618);
    font-size: 3.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-weight: 500;
    margin-bottom: 1.875rem;
}

@media (max-width: 768px) {
    .about-header {
       font-size: 2.5rem;
    }
}

.about-text {
    margin-bottom: 2rem;
    font-size: 1.125rem;
}

.about-image-style {
    width: 415px;
    height: 318px;
    margin-left: 2rem;
}

.description {
    color: var(--gray-950, #020618);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

}

.features-set {
    display: flex;
    width: 71.25rem;
    padding: 3.75rem 3.125rem;
    align-items: flex-start;
    gap: 1.25rem;
    border-radius: 1.375rem;
    border: 1px solid var(--gray-100, #F1F5FB);
    background: var(--gray-50, #F8FAFD);
    width: 100%;
    margin: 8.75rem 0 8.75rem 0;
}

@media (max-width: 768px) {
    .features-set {
       padding: 1rem;
    }
}

.main-title {
    display: flex;
    justify-content: space-between;
    margin: 3.125rem;
    padding-bottom: 1rem;
    align-items: flex-end;
    z-index: 2;
}

@media (max-width: 1024px) {
    .main-title {
       padding: 3rem;
       margin: 1rem;
    }
}
@media (max-width: 768px) {
    .main-title {
       padding: 1rem;
    }
}


.main-title button {
    display: flex;
    padding: 2.25rem 2.8125rem;
    width: 20rem;
    height: 1.8rem;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border-radius: 1.375rem;
    background: var(--White, #FFF);
}

.main-button {
    color: #020618; 
    font-weight: 600; 
    font-size: 1.5rem; 
    line-height: 1.75; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.375rem;
    background-color: #ffffff; 
    padding: 2rem 2.5rem; 
    width: 26.25rem;
}

@media (max-width: 1024px) {
    .main-button {
       padding: 1.4rem;
       width: 20rem;
    }
}

@media (max-width: 768px) {
    .main-button {
       padding: 1rem;
       width: 18rem;
    }
}


.main-title h1 {
    color: var(--White, #FFF);
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    width: 40rem;
    font-weight: 500;

}

@media (max-width: 1024px) {
    .main-title h1 {
        font-size: 2.5rem;
        width: 35rem;
    }
}




.nav-mid {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

@media (max-width: 768px) {
    .nav-link {
        font-size: 1rem;  
    }
}
@media (max-width: 768px) {
    .nav-mid {
        flex-direction: column;
        gap: 5px;
    }
}


.about-text p {
    color: var(--gray-950, #020618);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 1.575rem */
}

.white-btn {
    color: var(--White, #FFF);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}

.about-text button {
    display: flex;
    width: 19.75rem;
    padding: 1.25rem 1.25rem 1.25rem 1.875rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background: var(--purple-500, #443BBC);

    /* 1.2rem */
}


.landing-section {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
  
}

.discover-all-btn {
    display: flex;
    margin-top: 1.25rem;
    padding: 1.25rem 3.75rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1rem;
    background: var(--purple-500, #443BBC);
}


.desc {
    margin: 5rem 5rem;
}

@media (max-width: 1024px) {
    .desc {
      margin: 3rem 3rem;
    }
}
@media (max-width: 768px) {
    .desc {
      margin: 0 2rem 0 2rem;
    }
}


.faq-box {
    margin-top: 8.75rem;
    margin-bottom: 8.75rem;
}

.footer-links {
    display: flex;
    gap: 1.5rem;
    font-weight: 500;
    font-size: 0.875rem; 
    color: #020618;
    line-height: 1.5; 
}

@media (max-width: 768px) {
    .footer-links {
        flex-direction: column;
      margin: 0 2rem 0 2rem;
    }
}
