.main {
 display: flex;
  flex-direction: column; 
  color: #fff; 
  justify-content: center; 
  align-items: center; 
  border-radius: 1.5rem; 
  padding: 5rem 1rem; 
  border: 1px solid #F1F5FB;
  position: relative;
  top: 0;
  left: 0;
}

:root {
    --app-bar-margin: 1.5rem; /* You can replace this with your desired margin value */
  }

@media (max-width: 1024px) {
    .main {
        padding: 4rem 1.25rem;
    }
}

.landing-bg {
    object-fit: cover;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
}

.main-bg {
    height: 430px;
    width: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 25px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

@media (max-width: 768px) {
    .main-bg {
        height: 480px;
    }
}



.main-heading {
    color: #fff; 
    margin-bottom: 2rem; 
    font-weight: 600; 
    line-height: 3.9rem; 
    font-size: 3.125rem; 
}

@media (max-width: 1024px) {
    .main-heading {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}


.main-search {
    display: flex;
    gap: 0.75rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.main-niche {
    display: flex;
    margin-bottom: 3rem;
    gap: 2.25rem;

}

@media (max-width: 768px) {
    .main-niche {
        margin-bottom: 2rem;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.main-input {
    width: 44rem;
    font-size: 1.125rem;
    line-height: 1.25;
    display: block;
    padding: 1.25rem; 
    padding-left: 3rem;
    justify-content: space-between;
    align-items: center;
    border-radius: 1rem;
    background-color: #ffffff; 
}

@media (max-width: 768px) {
    .main-input {
        width: 30rem;
    }
}

.sign-form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.62rem; 
    border-radius: 1.375rem;
    align-items: center;
    justify-content: flex-start;
    background-color:#F8FAFD ;
    padding: 5rem 10.375rem 5rem 10.375rem;
}


.admin-main {
    margin: 0 3rem;
    height: 50%;
}

.admin-icon {
    fill: #F1F5FB;
}

.admin-container {
    width: 60%;
    padding: 20px;
    display: flex;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #F8FAFD;
    border-radius: 1.375rem;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.imageUpload-container{
    width: 60%;
    margin-top: 100px;
    margin-bottom: 140px;
    padding: 20px;
    display: flex;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #F8FAFD;
    border-radius: 1.375rem;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed; /* or 'absolute' */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  
  .inlineWrapper {
    position: static; /* or 'relative' */
    width: auto;
    height: auto;
  }
  
  .loading {
      display: inline-block;
      position: relative;
      width: 64px;
      height: 64px;
    }
    .loading div {
      position: absolute;
      background: #fff;
      opacity: 1;
      border-radius: 50%;
      animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
    .loading div:nth-child(2) {
      animation-delay: -.7s;
    }
    @keyframes loading {
      0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
      }
    }



